@import "../../global.scss";



.portfolio{

    display: flex;
    flex-direction: column;
    align-items: center;
    background-color:white;

    h1{
        margin-top: 10%;
        font-size: 50px;

        @include mobile {
            font-size: 20px;
            margin-top: 30%;
        }
    }

    ul{
        margin: 10px;
        padding: 0;
        list-style: none;
        display: flex;

        @include mobile {
            flex-wrap: wrap;
            justify-content: center;
        }

        li{
            font-size: 21px;
            margin-right: 75px;
            padding: 7px;
            border-radius: 10px;
            cursor: pointer;
    
            &.active{
                background-color: $mainColor;
                color: white;
            }
        }
    }


    .container{
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        @include mobile {
            width: 100%;
        }

        .item{
            width: 300px;
            height: 430px;
            border-radius: 20px;
            border: 1px solid white;
            margin: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            position: relative;
            transition: all .5s ease;
            cursor: pointer;

            @include mobile {
                width: 130px;
                height: 120px;
            }

            h3{
                position: absolute;
                font-style: 20px;
            }

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 1;
                
            }

            &:hover{
                background-color: $mainColor;
                img{
                    opacity: 0.2;
                    z-index: 0;
                }
            }
        }
    }
}