@import "../../global.scss";


.contact {
  background-color: white;
  display: flex;

  @include mobile {
    flex-direction: column;
}

  .left {
    flex: 1;
    overflow: hidden;
  }
  img{
      height: 100%;
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2{
        font-size: 45px;

        @include mobile {
           margin-bottom: 20px;
        }
    }

    form{
        width: 70%;
        height: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        input{
            width: 300px;
            height: 30px;
            font-size: 14px;
            margin-bottom: 40px;

            @include mobile {
                width: 200px;
                height: 20px;
            }
        }

        textarea{
            width: 300px;
            height: 200px;
            font-size: 14px;
            margin-bottom: 40px;

            @include mobile {
                width: 200px;
                height: 100px;
                margin-bottom: 20px;
            }
        }

        button{
            width: 150px;
            height: 30px;
            color: white;
            background-color: crimson;
            border: none;
            border-radius: 5px;
            font-weight: 700;
            cursor: pointer;
            margin-bottom: 40px;

            @include mobile {
                margin-bottom: 10px;
            }

            &:focus{
                outline: none;
            }
        }

        span{
            color: black;
        }
    }
  }
}
